import { motion } from "framer-motion";
import { Clock, TrendingUp } from "lucide-react";
import * as React from "react";

export type SortOption = "new" | "top";

interface SortingProps {
	currentSort: SortOption;
	onSortChange: (sort: SortOption) => void;
	disabled: boolean;
}

export function SortingToggle({
	currentSort,
	onSortChange,
	disabled,
}: SortingProps) {
	const toggleSort = () => {
		if (disabled) return;
		onSortChange(currentSort === "new" ? "top" : "new");
	};

	if (disabled) {
		return (
			<div className="relative w-20 h-7 bg-secondary rounded-full p-1 flex items-center motion-preset-fade-sm">
				<div className="absolute w-8 h-5 bg-muted-foreground rounded-full flex items-center justify-center opacity-60"></div>
			</div>
		);
	}

	return (
		<button
			type="button"
			onClick={toggleSort}
			className="relative w-20 h-7 bg-secondary rounded-full p-1 flex items-center motion-preset-fade-sm"
			aria-label={`Sort by ${currentSort === "new" ? "Top Rated" : "Newest"}`}
		>
			<motion.div
				className="absolute w-8 h-5 bg-primary rounded-full flex items-center justify-center"
				animate={{ x: currentSort === "new" ? 0 : 40 }}
				transition={{ type: "spring", stiffness: 700, damping: 40 }}
			>
				{currentSort === "new" ? (
					<Clock className="w-4 h-4 text-primary-foreground" />
				) : (
					<TrendingUp className="w-4 h-4 text-primary-foreground" />
				)}
			</motion.div>
			<span
				className={`w-12 flex items-center justify-center text-xs font-medium ${currentSort === "new" ? "text-primary-foreground" : "text-secondary-foreground"}`}
			>
				<Clock className="w-4 h-4 text-secondary-foreground" />
			</span>
			<span
				className={`w-12 flex items-center justify-center text-xs font-medium ${currentSort === "new" ? "text-primary-foreground" : "text-secondary-foreground"}`}
			>
				<TrendingUp className="w-4 h-4 text-secondary-foreground" />
			</span>
		</button>
	);
}
