"use client";

import * as React from "react";

import { useMediaQuery } from "@/hooks/use-media-query";
import type { CarouselGameType, Game } from "@/types/game";

import {
	GameThumbSkeleton,
	GameThumbnail,
} from "@/components/game-thumb/game-thumb";
import {
	type SortOption,
	SortingToggle,
} from "@/components/sorting-toggle/sorting-toggle";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "@/components/ui/carousel";
import { Skeleton } from "@/components/ui/skeleton";
import { VideoThumbnailCarousel } from "@/components/video-carousel/video-carousel";
import { VideoThumbSkeleton } from "@/components/video-thumb/video-thumb";
import { getGameNewVideos, getGameTopVideos } from "@/data/strapi/videos-api";
import type { Video, Video_Flat } from "@/types/video";
import { useCallback, useEffect, useState } from "react";

type CarouselProps = {
	games: Game[];
	carouselType: CarouselGameType;
};

export function GameThumbnailCarousel({ games, carouselType }: CarouselProps) {
	const isXs = useMediaQuery("(max-width: 400px)");
	const isSm = useMediaQuery("(min-width: 401px) and (max-width: 600px)");
	const isMd = useMediaQuery("(min-width: 601px) and (max-width: 1023px)");
	const isLg = useMediaQuery("(min-width: 1024px)");

	const getSlidesToScroll = () => {
		if (isXs) return 1;
		if (isSm) return 2;
		if (isMd) return 3;
		if (isLg) return 4;
		return 1;
	};

	const [selectedGame, setSelectedGame] = useState<Game>();
	const [gamesLoading, setGamesLoading] = useState<boolean>(true);
	const [videosLoading, setVideosLoading] = useState<boolean>(true);
	const [sortOption, setSortOption] = useState<SortOption>("new");
	const [gameVideos, setGameVideos] = useState<Video_Flat[]>([]);

	const cleanedGamesList = games.filter(
		(g) => g.video_count || g.video_count !== 0,
	);

	const onGameClick = useCallback((game: Game) => {
		setSelectedGame(game);
	}, []);

	useEffect(() => {
		const firstGame = cleanedGamesList[0];
		setSelectedGame(firstGame);
		setGamesLoading(false);
		// biome-ignore lint:
	}, []);

	useEffect(() => {
		const fetchVideos = async (gameId: number): Promise<Video[]> => {
			const getGameFN =
				sortOption === "new" ? getGameNewVideos : getGameTopVideos;

			return await getGameFN(gameId).then((videos) => {
				const flatGameVideos = videos.map(
					(v) => ({ id: v.id, ...v.attributes }) as Video_Flat,
				);

				setGameVideos(flatGameVideos);
				return videos;
			});
		};

		if (selectedGame) {
			setVideosLoading(true);
			fetchVideos(Number.parseInt(selectedGame.id)).then(() =>
				setVideosLoading(false),
			);
		}
	}, [selectedGame, sortOption]);

	return (
		<div className="flex flex-col">
			{!gamesLoading && cleanedGamesList.length > 0 && selectedGame ? (
				<Carousel
					className="flex-1 w-full h-full pb-1 border-b-1 border-slate-300 dark:border-slate-700"
					opts={{
						align: "start",
						loop: false,
						dragFree: false,
						dragThreshold: 20,
						duration: 20,
						skipSnaps: true,
						slidesToScroll: getSlidesToScroll(),
					}}
				>
					<CarouselContent className="">
						{games.map((game, index) => (
							<CarouselItem
								key={`gth-${game.id}-${index}`}
								className="basis-[160px] sm:basis-[190px] mr-2"
							>
								<GameThumbnail
									game={game}
									onClickHandler={onGameClick}
									index={index}
									carouselType={carouselType}
									isActive={game.id === selectedGame.id}
								/>
							</CarouselItem>
						))}
					</CarouselContent>
					<CarouselPrevious className="translate-x-14 -translate-y-8 w-10 h-10 hidden sm:flex z-10" />
					<CarouselNext className="-translate-x-14 -translate-y-8 w-10 h-10 hidden sm:flex z-10" />
					<div className="absolute bg-gradient-to-l from-background w-3 -right-1 top-0 h-full z-0" />
				</Carousel>
			) : (
				<GamesLoadingSkeleton />
			)}
			<div className="py-2">
				<div className="w-full flex flex-row justify-between mb-2 items-center h-6.5">
					{!videosLoading ? (
						gameVideos.length > 0 ? (
							<h5 className="text-[14px] line-clamp-1 pr-2 motion-preset-fade-sm motion-delay-150">
								{sortOption.charAt(0).toUpperCase() + sortOption.slice(1)}{" "}
								videos for {selectedGame?.name ?? "..."}
							</h5>
						) : (
							<div className=""></div>
						)
					) : (
						<Skeleton className="h-6.5 w-[175px] rounded-md motion-preset-fade-sm" />
					)}
					<SortingToggle
						disabled={videosLoading || gameVideos.length === 0}
						currentSort={sortOption}
						onSortChange={setSortOption}
					/>
				</div>
				{!videosLoading && gameVideos ? (
					<VideoThumbnailCarousel videos={gameVideos} game={selectedGame} />
				) : (
					<VideoLoadingSkeleton />
				)}
			</div>
		</div>
	);
}

const VideoLoadingSkeleton = () => (
	<div className="flex flex-row gap-2 overflow-hidden h-[240px] sm:h-[275px]">
		<VideoThumbSkeleton />
		<VideoThumbSkeleton />
		<VideoThumbSkeleton />
		<VideoThumbSkeleton />
	</div>
);

const GamesLoadingSkeleton = () => (
	<div className="flex flex-row gap-2 overflow-hidden h-[205px] sm:h-[235px]">
		<GameThumbSkeleton />
		<GameThumbSkeleton />
		<GameThumbSkeleton />
		<GameThumbSkeleton />
		<GameThumbSkeleton />
		<GameThumbSkeleton />
	</div>
);
